import FaqComps from "../components/FaqComps";

function Faqs() {
  return (
    <div>
      <FaqComps />
    </div>
  );
}

export default Faqs;
