import ContactComps from "../components/ContactComps";

function Contact() {
  return (
    <div>
      <ContactComps />
    </div>
  );
}

export default Contact;
